import { isWechatWorkApp, isWechatApp } from './utils/platform';
import { request, envConfig, setStaffAccountId, setStaffAccessToken, staffRequest } from './utils/request';
import uniApi from './utils/uniApi';

/**
 * 员工类
 */
class Staff {
  /**
   * @private
   * @type {Staff}
   */
  static instance = null;

  /**
   * 当前渠道 ID
   *
   * @private
   * @type {string}
   */
  _channelId = '';

  /**
   * 当前渠道 openId
   *
   * @private
   * @type {string}
   */
  _openId = '';

  /**
   * 创建员工实例（单例）
   */
  constructor() {
    const { instance } = Staff;
    if (instance) {
      return instance;
    }
    Staff.instance = this;
    return this;
  }

  /**
   * 员工 OAuth
   *
   * @param {object} options - 选项
   * @param {string} [options.weappId] - 小程序的 appId，since 1.20.2
   * @param {string} [options.appId] - 企业微信应用 agentId
   * @param {string} [options.corpId] - 企业微信的 cropId
   * @param {string} [options.suiteId] - 企业微信的 suiteId，since 1.29.0
   * @param {string} [options.code] - 企业微信小程序[用户登录凭证](https://open.work.weixin.qq.com/api/doc/90000/90136/91506)
   * @param {string} [options.accessToken] - 用户令牌，标识当前授权的 Staff
   * @param {string} [options.accountId] - 租户 Id，用于集团租户登录
   *
   * @example
   * // 企微自建小程序应用授权，在初始化 Mai 时不能传入 accountId
   * const mai = new Mai(env, false);
   * await mai.staff.signin({ weappId });
   *
   * @example
   * // 第三方应用授权，在初始化 Mai 时不能传入 accountId
   * const mai = new Mai(env, false);
   * await mai.staff.signin({ suiteId });
   *
   * @example
   * // 微信公众号 H5 或 企业微信 H5 授权后 url query 后会携带 accessToken 参数
   * const mai = new Mai(env, accountId);
   * const { accessToken } = mai.qs.parse(location.search, { ignoreQueryPrefix: true });
   * await mai.staff.signin({ accessToken });
   *
   * @example
   * // 企业微信自建小程序应用授权，由于 appId 需要小程序发布后和企业微信自建应用绑定后才能生成，给开发带来不便，已弃用。建议使用 weappId 进行授权。
   * const mai = new Mai(env, accountId);
   * await mai.staff.signin({ appId, corpId });
   *
   * @returns {Promise<void>}
   */
  async signin(options = {}) {
    let accountId = options.accountId || envConfig.accountId;
    // 微信公众号 H5 或 企业微信 H5
    if (options.accessToken) {
      setStaffAccessToken(options.accessToken);
      if (options.accountId) {
        setStaffAccountId(accountId);
      }
      return;
    }

    if (isWechatApp) {
      if (isWechatWorkApp) {
        if (!options.weappId && !options.corpId && !options.suiteId) {
          throw new Error('weappId or corpId or suiteId is required');
        }
        if (options.corpId && !options.appId) {
          throw new Error('appId is required when authorize by corpId');
        }

        const data = {
          weappId: options.weappId,
          appId: options.appId,
          corpId: options.corpId,
          suiteId: options.suiteId,
          code: options.code,
        };
        if (!data.code) {
          const { code } = await uniApi.qy.login();
          data.code = code;
        }
        let baseURL = envConfig.oauthApiBaseUrl;
        if (accountId) {
          baseURL = `${envConfig.oauthApiBaseUrl}/${accountId}`;
        }
        const auth = await request.post('/v2/work/weapp/oauth', data, { baseURL });
        if (!accountId) {
          accountId = auth.accountId;
          setStaffAccountId(accountId);
        }

        setStaffAccessToken(auth.accessToken);
        this._channelId = auth.channelId;
        this._openId = auth.openId;
      }
    }
  }

  /**
   * 获取 mai.staffRequest 租户 Id
   *
   * @returns {string}
   */
  getAccountId() {
    return staffRequest.defaults.headers['X-Account-Id'];
  }

  /**
   * 设置 mai.staffRequest 租户 Id
   *
   * @param {string} accountId
   */
  setAccountId(accountId) {
    setStaffAccountId(accountId);
  }

  /**
   * 获取 channelId，需要授权 {@link Staff#signin}
   *
   * @returns {string}
   */
  getChannelId() {
    return this._channelId;
  }

  /**
   * 获取员工当前渠道 openId，需要授权 {@link Staff#signin}
   *
   * @returns {string}
   */
  getOpenId() {
    return this._openId;
  }

  /**
   * 获取 accessToken，需要授权 {@link Staff#signin}
   *
   * @since 1.40.0
   * @returns {string}
   */
  getAccessToken() {
    return staffRequest.defaults.headers['X-Access-Token'];
  }

  /**
   * 清除 accessToken 退出登录
   *
   * @since 1.34.0
   */
  signout() {
    setStaffAccessToken();
  }
}

export default Staff;
