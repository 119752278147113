import qs from 'qs';
import { initRequest, outsideRequest, envConfig, setAccountId, outsideStaffRequest, getClientId, setStaffApiBaseUrl, setOauthApiBaseUrl, setCdnBaseUrl } from './utils/request';
import Member from './Member';
import Staff from './Staff';
import MemberEvent from './MemberEvent';
import Weapp from './Weapp';
import OssImage from './ossProvider/OssImage';
import CosImage from './ossProvider/CosImage';
import Captcha from './Captcha';
import WxH5 from './WxH5';
import Enums from './Enums';

/**
 * maijs 类
 */
class Mai {
  /**
   * @type {Member}
   */
  member = null;

  /**
   * @type {Staff}
   */
  staff = null;

  /**
   * @type {Weapp}
   */
  weapp = null;

  /**
   * @type {MemberEvent}
   */
  memberEvent = null;

  /**
   * axios 对象，使用方式参考 {@link https://github.com/axios/axios}
   * @type {AxiosInstance}
   */
  request = null;

  /**
   * axios 对象，使用方式参考 {@link https://github.com/axios/axios}
   * @type {AxiosInstance}
   */
  staffRequest = null;

  /**
   * @type {typeof OssImage}
   */
  OssImage = null

  /**
   * @type {typeof Enums}
   */
  Enums = Enums

  /**
   * @type {typeof Captcha}
   */
  Captcha = Captcha;

  /**
   * @type {WxH5}
   */
  wxH5 = null;

  /**
   * @type {typeof envConfig}
   */
  envConfig = envConfig;

  /**
   * @type {typeof qs}
   */
  qs = qs;

  /**
   * 根据 env 和 accountId 创建 maijs 实例
   *
   * @param {string} env - 环境变量，可选值 staging，production，usp-staging，usp-production，masterkong-production
   * @param {string} accountId - 群脉账号 id
   * @param {object} [options={}] -  可选参数
   * @param {string} [options.appName] - 应用名称，将拼接到日志的 category 中
   * @param {string} [options.appVersion] - 应用版本
   * @param {string} [options.oauthApiBaseUrl] - OAuth 网络请求 Base URL
   * @param {string} [options.consumerApiBaseUrl] - Consumer API 网络请求 Base URL
   * @param {string} [options.businessApiBaseUrl] - Staff API 网络请求 Base URL
   * @param {string} [options.cdnBaseUrl] - CDN 资源 Base URL
   * @param {'aliyun' | 'tencent' | 'minio' | 'aws' | 'azure'} [options.ossProvider='aliyun'] - OSS 提供商
   * @param {boolean} [options.useAppIdOauthDomain] - 是否使用 $APPID.$OAUTH_DOMAIN 形式的授权域名
   * @param {boolean} [options.withStaffRequest=false] - 是否使用 staffRequest
   */
  constructor(env, accountId, options = {}) {
    if (Mai._instance) {
      return Mai._instance;
    }
    // TODO 为 1.14 以前版本升级而做了兼容，当绝大数用户高于 1.14 版本后可以移除。
    const deprecatedEnvMap = {
      'scrm-staging': 'staging',
      'scrm-production': 'production',
    };
    if (deprecatedEnvMap[env]) {
      // eslint-disable-next-line no-console
      console.warn(`【Deprecated】 env "${env}" has been deprecated. Please update to "${deprecatedEnvMap[env]}" ASAP.`);
    }

    // eslint-disable-next-line no-param-reassign
    env = deprecatedEnvMap[env] || env;
    Mai.validate(env, accountId);
    initRequest(env, accountId, options);
    this.memberEvent = new MemberEvent();
    this.member = new Member();
    this.staff = new Staff();
    this.weapp = new Weapp();
    this.request = outsideRequest;
    this.staffRequest = outsideStaffRequest;
    this.wxH5 = new WxH5();
    this.OssImage = options?.ossProvider === 'tencent' ? CosImage : OssImage;

    Mai._instance = this;
    // eslint-disable-next-line no-console
    console.info(`maijs@${process.env.SDK_VERSION} initialized`);
  }

  /**
   * 设置 mai.request 租户 Id
   * @private
   * @deprecated 请使用 mai.member.setAccountId 或 mai.staff.setAccountId 代替
   * @param {string} accountId
   */
  setAccountId(accountId) {
    // eslint-disable-next-line no-console
    console.error('【Deprecated】mai.setAccount 已废弃，请使用 `mai.member.setAccountId` 或 `mai.staff.setAccountId` 代替');
    setAccountId(accountId);
  }

  /**
   * 获取客户端 id
   *
   * @since 1.33.0
   * @returns {string}
   */
  getClientId() {
    return getClientId();
  }

  /**
   * 设置 mai.staffRequest 的接口请求 Staff API Base URL
   *
   * @since 1.39.0
   * @param {string} staffApiBaseUrl - Staff API 网络请求 Base URL
   */
  setStaffApiBaseUrl(staffApiBaseUrl) {
    setStaffApiBaseUrl(staffApiBaseUrl);
  }

  /**
   * 设置 envConfig 的 OAuth API Base URL
   *
   * @since 1.42.0
   * @param {string} oauthApiBaseUrl - OAuth API 网络请求 Base URL
   */
  setOauthApiBaseUrl(oauthApiBaseUrl) {
    setOauthApiBaseUrl(oauthApiBaseUrl);
  }

  /**
   * 设置 envConfig 的 CDN Base URL
   *
   * @since 1.42.0
   * @param {string} cdnBaseUrl - CDN Base URL
   */
  setCdnBaseUrl(cdnBaseUrl) {
    setCdnBaseUrl(cdnBaseUrl);
  }

  /**
   * 验证构造器参数类型
   *
   * @private
   * @param {string} env - 环境变量
   * @param {string} accountId - 群脉账号 id
   */
  static validate(env, accountId) {
    if (!env) {
      throw new Error('env is required');
    }
    // 多租户共用一个企微小程序时，不会配置 accountId，此时需要在 staff.signin() 后设置 accountId
    if (!accountId && accountId !== false) {
      throw new Error('accountId is required');
    }
    if (accountId && accountId.length !== 24) {
      throw new Error('accountId is invalid');
    }
  }
}

Mai.prototype.qs = qs;

export default Mai;
