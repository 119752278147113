import { request, envConfig, staffRequest } from './utils/request';
import { uuid } from './utils/string';
import Member from './Member';
import Staff from './Staff';
import uniApi from './utils/uniApi';

/**
 * 小程序类(微信小程序、字节小程序、支付宝小程序)
 */
class Weapp {
  /**
   * 上传临时文件
   *
   * @param {string} tempFilePath - 微信小程序临时文件路径
   * @param {object} [ossConfig] - oss 相关配置，为空时会自动获取
   * @param {boolean} [ossConfig.withStaffRequest=false] - 是否使用 StaffRequest 获取 OSS signature
   * @param {string} ossConfig.domain - oss 访问域名
   * @param {object} ossConfig.formData - oss 上传文件需携带的相关字段
   * @param {string} ossConfig.directory - oss 上传目录
   * @returns {Promise<object>}
   */
  async uploadTempFile(tempFilePath, ossConfig = {}) {
    if (!/\.\w+$/.test(tempFilePath)) {
      throw new Error('invalid tempFilePath');
    }

    let checkedOssConfig = { ...ossConfig };
    if (!ossConfig.directory) {
      const rest = ossConfig.withStaffRequest ? staffRequest : request;
      checkedOssConfig = await rest.get('/v2/oss/signature');
    }

    let openId = Member.instance.getOpenId();
    if (ossConfig?.withStaffRequest || !openId) {
      openId = Staff.instance.getOpenId();
    }

    const source = tempFilePath;
    const ext = /\.\w+$/.exec(source)[0];
    const file = `${checkedOssConfig.directory}/followers/${openId}/${uuid()}${ext}`;
    const useUploadUrlProviders = ['minio', 'aws'];
    const config = {
      url: !useUploadUrlProviders.includes(ossConfig.ossProvider) ? checkedOssConfig.domain : checkedOssConfig.url,
      filePath: source,
      name: 'file',
      fileName: 'file',
      fileType: 'image',
      // fileName 与 fileType 为支付宝 uploadFile 接口所需字段
      formData: {
        ...checkedOssConfig.formData,
        key: file,
      },
    };
    if (ossConfig.ossProvider === 'azure') {
      const baseUrl = ossConfig.withStaffRequest ? envConfig.businessApiBaseUrl : envConfig.consumerApiBaseUrl;
      config.url = `${baseUrl}/v1/oss/azureBlob`;
    }
    await uniApi.uploadFile(config);
    return {
      source,
      url: `${checkedOssConfig.domain}/${file}`,
    };
  }

  /**
   * 上传图片，参数参考微信小程序 chooseImage 定义，需要授权 {@link Member#signin}
   *
   * @param {object} options
   * @param {number} options.count - 最多可以选择的图片张数
   * @param {"original" | "compressed"} options.sizeType - 所选的图片的尺寸
   * @param {"album" | "camera"} options.sourceType - 选择图片的来源
   * @param {boolean} options.needImageInfo - 是否需要返回图片信息
   * @param {boolean} [options.ignoreLoading=false] - 是否展示 Loading
   * @param {boolean} [options.withStaffRequest=false] - 是否使用 StaffRequest 获取 OSS signature
   * @returns {Promise<Array<object>>}
   */
  async uploadImages(options) {
    if (!options.count) {
      throw new Error('count is required');
    }

    const { tempFilePaths } = await uniApi.chooseImage(options);
    try {
      const items = [];
      if (!options.ignoreLoading) {
        uniApi.showLoading({ mask: true, title: '上传中...' });
      }

      const rest = options.withStaffRequest ? staffRequest : request;
      const ossConfig = await rest.get('/v2/oss/signature');
      ossConfig.withStaffRequest = options.withStaffRequest;
      for (let index = 0; index < tempFilePaths.length; index += 1) {
        // eslint-disable-next-line
        const item = await this.uploadTempFile(tempFilePaths[index], ossConfig, index);
        if (options.needImageInfo) {
          // eslint-disable-next-line
          const { type, height, width } = await uniApi.getImageInfo({ src: item.source });
          item.type = type;
          item.height = height;
          item.width = width;
        }
        items.push(item);
      }

      return items;
    } finally {
      if (!options.ignoreLoading) {
        uniApi.hideLoading();
      }
    }
  }

  /**
   * 通过微信小程序绑定手机号，需要授权 {@link Member#signin}
   *
   * @param {object} options
   * @param {string} options.code - [用户登录凭证](https://developers.weixin.qq.com/miniprogram/dev/api/wx.login.html)
   * @param {string} options.iv [加密算法的初始向量](https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/getPhoneNumber.html)
   * @param {string} options.encryptedData [包括敏感数据在内的完整用户信息的加密数据](https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/getPhoneNumber.html)
   * @param {boolean} options.allowMerge 是否允许自动 merge 手机号相同的 member。
   * @returns {object}
   */
  async bindPhone(options) {
    if (!options.code) {
      throw new Error('code is required');
    }

    if (!options.iv) {
      throw new Error('iv is required');
    }

    if (!options.encryptedData) {
      throw new Error('encryptedData is required');
    }

    const member = await request.put('/v2/member/bindPhoneFromMiniProgram', options);
    Member.instance.updateAs(member);
    return member;
  }

  /**
   * 微信小程序的 version 可以从 request referer 中获取，详情参考 [微信开发者文档](https://developers.weixin.qq.com/miniprogram/dev/framework/ability/network.html)
   *
   * @returns {string} 取值范围 undefined | devtools | 0 | N+
   * - 如果未 signin 则返回 undefined
   * - 如果使用开发工具，则返回 devtools
   * - 如果是开发版或者体验版，则返回 0
   * - 如果是正式版，则返回 N+，且随着每次发布自增
   */
  getVersion() {
    return envConfig.weappVersion;
  }
}

export default Weapp;
