import { isH5 } from './platform';
import uniApi from './uniApi';

const localStorage = {
  setItem: () => { },
  getItem: () => { },
  removeItem: () => { },
};

if (isH5) {
  localStorage.setItem = (key, value) => {
    const jsonStr = JSON.stringify(value);
    window.localStorage.setItem(key, jsonStr);
  };

  localStorage.getItem = (key) => {
    const jsonStr = window.localStorage.getItem(key);
    return JSON.parse(jsonStr);
  };

  localStorage.removeItem = (key) => {
    window.localStorage.removeItem(key);
  };
} else {
  localStorage.setItem = (key, value) => {
    uniApi.setStorageSync(key, value);
  };

  localStorage.getItem = (key) => {
    return uniApi.getStorageSync(key);
  };

  localStorage.removeItem = (key) => {
    uniApi.removeStorageSync(key);
  };
}

export default localStorage;
