import { getAccessToken } from './utils/accessToken';
import { createCacheStore } from './utils/cache';
import { isObject, isFunction } from './utils/lang';
import { isWechatApp, isAlipayApp, isBytedanceApp } from './utils/platform';
import { request, envConfig, setAccessToken, getClientId, setAccountId } from './utils/request';
import uniApi from './utils/uniApi';
import Enums from './Enums';

/**
 * 客户类
 */
class Member {
  /**
   * @private
   * @type {Member}
   */
  static instance = null;

  /**
   * 当前客户信息
   *
   * @private
   * @type {object}
   */
  _member = null;

  /**
   * 当前渠道 ID
   *
   * @private
   * @type {object}
   */
  _social = null;

  /**
   * 当前渠道是否第一次授权 newChannel
   *
   * @private
   * @type {boolean}
   */
  _newChannel = false;

  /**
   * 缓存客户数据
   *
   * @private
   * @type {function}
   */
  _getCache = createCacheStore()

  /**
   * 创建客户实例(单例)
   */
  constructor() {
    const { instance } = Member;
    if (instance) {
      return instance;
    }
    Member.instance = this;
    return this;
  }

  /**
   * OAuth 且把当前客户与该 client 的匿名事件绑定
   *
   * @param {object} options - 选项
   * @param {string} [options.accessToken] - 用户令牌，标识当前授权的用户
   * @param {string} [options.appId] 小程序 appId，支持微信小程序、支付宝小程序、字节小程序
   * - 微信小程序如果最低版本大于 2.2.2 可以不传
   * - 支付宝小程序最低版本大于 2.7.17 可以不传
   * - 字节小程序如果最低版本大于 2.21.0 可以不传
   * @param {string} [options.code] - 微信小程序[用户登录凭证](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/login/wx.login.html)，仅支持微信小程序。在微信小程序插件中该参数必填
   * @param {boolean} [options.isGroup=false] - 集团登录，仅支持微信小程序
   * @param {string} [options.accountId] - 指定 accountId，仅支持微信小程序，用于集团租户登录
   * @param {string} [options.phone] - 手机号，仅支持通过手机号登录。如果账号未注册会自动注册并登录
   * @param {string} [options.phoneCode] - 手机验证码，仅支持通过手机号登录
   * @param {string} [options.source] - 注册来源，仅支持通过手机号登录
   * @param {boolean} [options.isActivated=false] - 客户会员卡激活状态，仅支持通过手机号登录
   *
   * @example
   * // 微信小程序
   * const mai = new Mai(env, accountId);
   * await mai.member.signin({ appId });
   *
   * @example
   * // H5 公众号授权，完成群脉公众号授权后 url query 后会携带 accessToken 参数
   * const mai = new Mai(env, accountId);
   * const { accessToken } = mai.qs.parse(location.search, { ignoreQueryPrefix: true });
   * await mai.member.signin({ accessToken });
   *
   * @example
   * // H5 手机号验证码登录
   * try {
   *   let phone = '18000000000';
   *   const captcha = new mai.Captcha();
   *   await captcha.sendSmsVerificationCode(phone);
   *   let code = '111111'; // 消费者收到的手机验证码，一般为 6 位数字。
   *   await mai.member.signin({ phone, code })
   * } catch (error) {
   *   if (error.ret === mai.Captcha.Ret.USER_CLOSED) {
   *     console.log('人机验证弹窗被关闭');
   *   } else {
   *     console.log('短信验证码发送失败');
   *   }
   * }
   *
   * @returns {Promise<void>}
   */
  async signin(options = {}) {
    const accessToken = getAccessToken(options);
    const accountId = options.accountId || envConfig.accountId;
    if (options.accountId) {
      setAccountId(options.accountId);
    }

    if (accessToken) {
      setAccessToken(accessToken);
      const { member, social } = await request.get('/v2/socialMember');
      this._member = member;
      this._social = social;
    } else if (options.phone) {
      const data = {
        phone: options.phone,
        code: options.phoneCode,
        source: options.source,
        isActivated: { value: options.isActivated },
      };
      const auth = await request.post('/v2/loginByPhone', data);
      setAccessToken(auth.accessToken);
      const { member } = await request.get('/v2/socialMember');
      this._member = member;
    } else if (isWechatApp) {
      await this.signinWeapp(options, accountId);
    } else if (isAlipayApp) {
      await this.signinAlipay(options, accountId);
    } else if (isBytedanceApp) {
      await this.signinBytedance(options, accountId);
    }

    try {
      await request.post('/v2/memberEventLogs/bindAnonymousToMember', { clientId: getClientId() });
    } finally {
      // 多页应用，如果在调了 signin() 后，页面跳转，浏览器则会 cancel 该请求
      // 支持调用方传 callback，确保 signin 成功或者失败后再做后续操作
      if (isObject(options) && isFunction(options.callback)) {
        // eslint-disable-next-line no-console
        console.warn('【Deprecated】 请尽快使用如下写法代替 callback：\nawait mai.member.signin(options);\n callback();\n或\nmai.member.signin(options).then(callback);');
        options.callback();
      }
    }
  }

  async signinWeapp(options, accountId) {
    const data = {
      scope: 'base',
      code: options.code,
      watermark: { appid: options.appId },
      is_group: JSON.stringify(options.isGroup || false),
    };

    if (!data.code) {
      const { code } = await uniApi.login();
      data.code = code;
    }

    if (uniApi.getAccountInfoSync) {
      const { miniProgram } = uniApi.getAccountInfoSync();
      data.watermark.appid = miniProgram.appId;
    } else if (!options.appId) {
      throw new Error('appId is required');
    }
    const baseURL = `${envConfig.oauthApiBaseUrl}/${accountId}`;
    const auth = await request.post('/v2/weapp/oauth', data, { baseURL });
    setAccessToken(auth.accessToken);
    this._member = auth.member;
    this._newChannel = auth.newChannel;
    if (auth.member) {
      const socials = [
        this._member.originFrom || {},
        ...(this._member.socials || []),
      ];
      this._social = socials.find((item) => {
        return item.channel === auth.channelId && item.openId === auth.openId;
      });
    }
  }

  async signinAlipay(options, accountId) {
    const data = {
      scope: options.scope || Enums.AlipayappAuthScope.AUTH_BASE,
      code: options.code,
      appId: options.appId,
    };

    if (!options.code) {
      const { authCode } = await uniApi.getAuthCode();
      data.code = authCode;
    }
    if (uniApi.getAppIdSync) {
      const { appId } = uniApi.getAppIdSync();
      data.appId = appId;
    } else if (!options.appId) {
      throw new Error('appId is required');
    }

    if (options.openUserInfo) {
      const parsedOpenUserInfo = JSON.parse(options.openUserInfo);
      const { response: openUserInfo } = parsedOpenUserInfo;

      const propertyIdsOfOpenUserInfo = ['name', 'img', 'gender', 'address'];
      const GenderMapping = {
        m: 'male',
        f: 'female',
      };
      const CountryCodeMapping = {
        CN: '中国',
      };

      const getParamsOfSetProperties = (searchedProperties, openUserInfo) => {
        const { avatar: img, nickName: name, gender, countryCode, province, city } = openUserInfo;
        const address = [CountryCodeMapping[countryCode], province, city];
        const memberPropertyValues = { img, name, gender: GenderMapping[gender], address };

        return propertyIdsOfOpenUserInfo.map((propertyId) => {
          const foundProperty = searchedProperties.find((property) => property.name === propertyId);
          if (!foundProperty) {
            return undefined;
          }
          const { type } = foundProperty;
          return { propertyId, type, value: memberPropertyValues[propertyId] };
        });
      };

      const searchedProperties = await this.searchPropertyInfo({ isDefault: true });
      const params = getParamsOfSetProperties(searchedProperties, openUserInfo);
      await this.setProperties(params);
    }

    const baseURL = `${envConfig.oauthApiBaseUrl}/${accountId}`;
    const auth = await request.post('/v2/alipayapp/oauth', data, { baseURL });
    setAccessToken(auth.accessToken);
    this._member = auth.member;
    this._newChannel = auth.newChannel;
    if (auth.member) {
      const socials = [
        this._member.originFrom || {},
        ...(this._member.socials || []),
      ];
      this._social = socials.find((item) => {
        return item.channel === auth.channelId && item.openId === auth.openId;
      });
      const { channelId: channel, openId } = auth;
      this._social = { ...this._social || {}, channel, openId };
    }
  }

  async signinBytedance(options, accountId) {
    const data = {
      scope: options.scope || 'base',
      code: options.code,
      appId: options.appId,
    };

    if (tt.getEnvInfoSync) {
      const { microapp } = tt.getEnvInfoSync();
      options.appId = microapp.appId;
    } else if (!options.appId) {
      throw new Error('bytedance appId is required');
    }

    if (!options.code) {
      const { code } = await uniApi.login();
      data.code = code;
    }

    if (data.scope === 'userinfo') {
      const { encryptedData, iv } = await uniApi.getUserInfo();
      data.encrypted_data = encryptedData;
      data.iv = iv;
    }

    const baseURL = `${envConfig.oauthApiBaseUrl}/${accountId}`;
    const auth = await request.post('/v2/bytedanceapp/oauth', data, { baseURL });
    setAccessToken(auth.accessToken);
    this._member = auth.member;
    this._newChannel = auth.newChannel;
    if (auth.member) {
      const socials = [
        this._member.originFrom || {},
        ...(this._member.socials || []),
      ];
      this._social = socials.find((item) => {
        return item.channel === auth.channelId && item.openId === auth.openId;
      });
      const { channelId: channel, openId } = auth;
      this._social = { ...this._social || {}, channel, openId };
    }
  }

  /**
   * 给当前客户打标签，需要授权 {@link Member#signin}
   *
   * @param {string[]} tags - 新标签数组，不能为空
   */
  addTags(tags = []) {
    if (!tags.length) {
      throw new Error('tags is required');
    }

    return request.put(
      '/v2/member/tag/add',
      { tags },
    );
  }

  /**
   * 创建活动传播链，需要授权 {@link Member#signin}。比如 A 邀请了 B 参与某项活动，那么活动裂变场景的集成流程：
   * - 用户 A 报名参与活动，生成专属海报后，调用此接口，inviterOpenId 为空。
   * - 用户 B 访问了 A 海报中的链接，调用此接口，inviterOpenId 为 A 的 openId，视为 A 邀请 B 参与活动。
   * - 如果之前 B 已经访问了用户 C 的海报，仍然视为 C 邀请了 B（一个用户只能被邀请一次）。
   *
   * @param {string} affiliateProgramId - 营销活动 ID
   * @param {string} [inviterOpenId] 邀请者 open ID
   */
  async trackAffiliation(affiliateProgramId, inviterOpenId) {
    if (!affiliateProgramId) {
      throw new Error('affiliateProgramId is required');
    }

    await request.post(
      `/v2/marketing/affiliatePrograms/${affiliateProgramId}/track`,
      { inviterOpenId },
    );
  }

  /**
   * 获取客户详情
   *
   * @param {Array<'Card'|'Properties'>} [extraFields=[]] - 控制返回客户扩展字段
   * - Card：返回值中会带有该客户的会员卡信息
   * - Properties：返回值中会带有该客户的属性设置
   * @param {boolean} [withCache=false] - 启用缓存，多次调用会从缓存中读取
   * @returns {Promise<object>}
   */
  getDetail(extraFields = [], withCache = false) {
    const getDetailFromRemote = async () => {
      const memberPromise = request.get('/v2/member', { params: { extraFields } });
      const promises = [memberPromise];
      if (extraFields.includes('Properties')) {
        const propertyPromise = this._getCache(
          'propertyInfo',
          () => this.searchPropertyInfo({ listCondition: { perPage: 999 } }),
        );
        promises.push(propertyPromise);
      }

      const [member, propertyInfoArray] = await Promise.all(promises);
      if (propertyInfoArray) {
        for (const property of member.properties) {
          const valueField = Object.keys(property).find((key) => /^value/.test(key));
          property.value = property[valueField] && property[valueField].value;
          delete property[valueField];

          const propertyInfo = propertyInfoArray.find((info) => info.id === property.id);
          property.propertyId = propertyInfo && propertyInfo.propertyId;

          if (property.propertyId) {
            Object.defineProperty(member.properties, property.propertyId, {
              value: property.value,
              enumerable: false,
            });
          }
        }
      }

      return member;
    };

    const cacheKey = extraFields.sort().join();
    return this._getCache(`getDetail:${cacheKey}`, getDetailFromRemote, !withCache);
  }

  /**
   * 搜索客户属性
   *
   * @param {object} options - 选项
   * @param {boolean} [options.isDefault=null] - 是否是默认客户属性
   * @param {boolean} [options.isVisible=null] - 是否是可见客户属性
   * @param {string[]} [options.ids=[]] - 客户属性 ID
   * @param {string[]} [options.names=[]] - 属性名称
   * @param {string[]} [options.propertyIds=[]] - 自定义属性 ID
   * @param {object} [options.listCondition={}] - 分页选项
   * @param {number} [options.listCondition.page=1] - 页码
   * @param {number} [options.listCondition.perPage=20] - 每页数据数目
   * @param {string[]} [options.listCondition.orderBy=[]] - 排序字段。按字段排序，例如 ["createdAt", "-createdAt"]
   * @returns {Promise<Array<object>>}
   */
  async searchPropertyInfo(options = {}) {
    const params = options;
    ['isDefault', 'isVisible', 'isVisibleInFilter'].forEach((fieldKey) => {
      if (options[fieldKey] !== undefined) {
        params[`${fieldKey}`] = { value: options[fieldKey] };
      }
    });

    const { items } = await request.get('/v2/member/properties', { params });
    return items;
  }

  /**
   * 设置客户属性
   *
   * @param {Object[]} properties 客户属性列表
   * @param {string} properties[].propertyId 客户属性 ID
   * @param {string} properties[].type 客户属性类型
   * @param {any} properties[].value 客户属性值
   * @returns {Promise<object>}
   */
  async setProperties(properties = []) {
    const items = [];
    for (const property of properties) {
      const { propertyId, type, value } = property;
      const item = { propertyId };
      const propertyTypeMap = {
        location: 'valueNumberArray',
        images: 'valueArray',
        address: 'valueArray',
        checkbox: 'valueArray',
        date: 'valueDate',
        datetime: 'valueDate',
        number: 'valueNumber',
        currency: 'valueNumber',
        bool: 'valueBool',
      };
      const fieldKey = propertyTypeMap[type] || 'valueString';
      item[fieldKey] = { value };
      items.push(item);
    }

    const result = await request.put('/v2/member', { properties: items, onlyValidateUpdated: true });
    return result;
  }

  /**
   * 获取 mai.request 租户 Id
   *
   * @returns {string}
   */
  getAccountId() {
    return request.defaults.headers['X-Account-Id'];
  }

  /**
   * 设置 mai.request 租户 Id
   *
   * @param {string} accountId
   */
  setAccountId(accountId) {
    setAccountId(accountId);
  }

  /**
   * 获取渠道 id，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 返回 signin 时 accessToken 对应的 channelId
   *
   * @returns {string}
   */
  getChannelId() {
    return this._social?.channel || this._channelId || '';
  }

  /**
   * 设置渠道 Id，如果授权过 {@link Member#signin}，accessToken 中 channelId 会覆盖当前值。
   *
   * @since 1.39.0
   * @param {string} [channelId] - 渠道 Id
   */
  setChannelId(channelId) {
    this._channelId = channelId;
  }

  /**
   * 获取手机号, 需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken 或者 phone
   *
   * @returns {string}
   */
  getPhone() {
    return this._member?.phone || '';
  }

  /**
   * 获取 access token，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken 或者 phone
   *
   * @since 1.40.0
   * @returns {string}
   */
  getAccessToken() {
    return request.defaults.headers['X-Access-Token'];
  }

  /**
   * 清除 accessToken 退出登录
   *
   * @since 1.34.0
   */
  signout() {
    setAccessToken();
  }

  /**
   * 获取客户 id，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken 或者 phone
   *
   * @returns {string}
   */
  getMemberId() {
    return this._member?.id || '';
  }

  /**
   * 获取客户是否激活，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken 或者 phone
   *
   * @returns {boolean}
   */
  isActivated() {
    return !!(this._member?.isActivated);
  }

  /**
   * 获取客户当前渠道信息，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken，返回该 accessToken 对应的 social
   *
   * @returns {object}
   */
  getSocial() {
    return this._social;
  }

  /**
   * 获取客户当前渠道 nickname，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken，返回该 accessToken 对应的 social 的 nickname
   *
   * @returns {string}
   */
  getNickname() {
    return this._social?.nickname || '';
  }

  /**
   * 获取客户当前渠道 openId，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken，返回该 accessToken 对应的 social 的 openId
   *
   * @returns {string}
   */
  getOpenId() {
    return this._social?.openId || '';
  }

  /**
   * 获取客户当前渠道 unionId，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken，返回该 accessToken 对应的 social 的 unionId
   *
   * @returns {string}
   */
  getUnionId() {
    return this._social?.unionId || '';
  }

  /**
   * 获取客户当前渠道是否授权，需要授权 {@link Member#signin}
   * - 支持微信小程序
   * - H5 需要 signin 时选项包含 accessToken，返回该 accessToken 对应的 social 是否授权
   *
   * @returns {boolean}
   */
  isSocialAuthorized() {
    return !!(this._social?.authorized);
  }

  /**
   * 获取客户是否关注，需要授权 {@link Member#signin}
   *
   * @since 1.41.0
   * @param {string} [channelId] 渠道 ID，如果不为空则返回指定渠道是否关注，为空则返回 accessToken 对应的 social 是否关注。
   *
   * @returns {boolean}
   */
  isSocialSubscribed(channelId) {
    if (!channelId || this._social?.channel === channelId) {
      return this._social?.subscribed || false;
    }

    const socials = [
      ...(this._member.socials || []),
      this._member.originFrom || {},
    ];

    const social = socials.find((item) => item.channel === channelId);
    return social?.subscribed || false;
  }

  /**
   * 更新当前客户信息
   *
   * @private
   * @param {Object} params 需要更新的字段集合
   */
  updateAs = (params) => {
    this._member = { ...this._member, ...params };
  }

  /**
   * 更新当前客户的 social 信息
   *
   * @private
   * @param {Object} member 最新的 member 对象
   */
  updateSocial = (member) => {
    const socials = [
      member.originFrom || {},
      ...(member.socials || []),
    ];

    this._social = socials.find((item) => {
      return item.channel === this._social.channel && item.openId === this._social.openId;
    });
  }

  /**
   * 判断用户在当前渠道是否第一次授权
   * - 仅小程序可用
   *
   * @returns {boolean}
   */
  isNewChannel() {
    return this._newChannel;
  }

  /**
   * 更新渠道信息并把当前渠道标记为已授权
   *
   * @since 1.47.0
   * @param {object} [social] - 渠道信息
   * @param {string} [social.avatar] - 头像，支持微信头像和通过 mai.weapp.uploadImages()、mai.weapp.uploadTempFile() 上传的图片
   * @param {string} [social.nickname] - 昵称
   * @param {'unknown' | 'male' | 'female'} [social.gender] - 性别， 'unknown' - 未知、'male' - 男性、 'female' - 女性
   * @param {string} [social.country] - 国家，支持腾讯返回的地址信息
   * @param {string} [social.province] - 省，支持腾讯返回的地址信息
   * @param {string} [social.city] - 市，支持腾讯返回的地址信息
   */
  async authSocial(social = {}) {
    const member = await request.post('/v2/member/authSocial', social);
    this.updateAs(member);
    this.updateSocial(member);
  }
}

export default Member;
