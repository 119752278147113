import { request, staffRequest, envConfig } from '../utils/request';
import uniApi from '../utils/uniApi';
import OssImage from './OssImage';

/**
 * COS 图像处理类，链式处理 COS 图像。详情参考 [COS 图片处理指南](https://cloud.tencent.com/document/product/460/36540)
 *
 * @extends {OssImage}
 */
class CosImage extends OssImage {
  /**
   *  COS 域名
   * @type {string}
   */
  static ossUrl = '';

  /**
   * @private
   *
   * @type {Promise}
   */
  static _initPromise = null;

  static init = async () => {
    if (!CosImage._initPromise) {
      const rest = envConfig.options.withStaffRequest ? staffRequest : request;
      CosImage._initPromise = rest.get('/v2/oss/signature').then((ossConfig) => {
        const [endpoint, folder] = ossConfig.url.replace('https://', '').split('/');
        CosImage.ossUrl = `http://${folder}.${endpoint}`;
      });
    }

    return CosImage._initPromise;
  };

  static source(url = '') {
    return new CosImage({ url });
  }

  static transferGravity(g) {
    const gravityTypes = {
      nw: 'northwest',
      ne: 'northeast',
      sw: 'southwest',
      se: 'southeast',
    };
    return gravityTypes[g] || g;
  }

  static transferWidthHeight(options) {
    let str = '';
    if (options.w) {
      str += `${options.w}x`;
    }
    if (options.h) {
      str += `x${options.h}`;
    }
    return str.replace('xx', 'x');
  }

  static transferFont(font) {
    const fontTypes = {
      fangzhengshusong: 'simsun宋体',
      fangzhengkaiti: 'simkai楷体',
      fangzhengheiti: 'simhei黑体',
      fangzhengfangsong: 'simfang仿宋',
      'wqy-zenhei': 'STHeiti Light华文黑体',
    };
    return fontTypes[font] || font;
  }

  resize(options) {
    let str = '';
    if (options.w && options.h && options.m === 'lfit') {
      str = `${options.w}x${options.h}`;
    } else if (options.w && options.h && options.m === 'mfit') {
      str = `!${options.w}x${options.h}r`;
    } else if (options.w && options.h && options.m === 'fixed') {
      str = `${options.w}x${options.h}!`;
    } else if (options.w || options.h) {
      str = CosImage.transferWidthHeight(options);
    } else if (options.l) {
      str = `${options.l}x${options.l}`;
    } else if (options.s) {
      str = `${options.s}x${options.s}`;
    } else if (options.p) {
      str = `!${options.p}p`;
    }
    if (options.m === 'pad') {
      str += `/pad/1/color/${uniApi.encodeBase64(`#${options.color}` || '#FFFFFF')}`;
    }
    str += '/ignore-error/1';
    return this._operate('thumbnail', str);
  }

  circle(options) {
    return this._operate('iradius', options.r);
  }

  crop(options) {
    let str = CosImage.transferWidthHeight(options);
    if (options.x) {
      str += `x${options.x}`;
    }
    if (options.y) {
      str += `x${options.y}`;
    }
    if (options.g) {
      str += `/gravity/${CosImage.transferGravity(options.g)}`;
    }
    return this._operate('cut', str);
  }

  roundedCorners(options) {
    return this._operate('rradius', options.r);
  }

  autoOrient(orient) {
    if (orient === 1) {
      return this._operate('auto-orient', orient);
    }
    return this;
  }

  rotate(rotate) {
    return this._operate('rotate', rotate);
  }

  blur(options) {
    let str = '';
    if (options.r) {
      str += `${options.r}`;
    }
    if (options.s) {
      str += `x${options.s}`;
    }
    return this._operate('blur', str);
  }

  bright(bright) {
    return this._operate('bright', bright);
  }

  contrast(contrast) {
    return this._operate('contrast', contrast);
  }

  sharpen(sharpen) {
    return this._operate('sharpen', sharpen);
  }

  format(format) {
    return this._operate('format', format);
  }

  interlace(interlace) {
    return this._operate('interlace', interlace);
  }

  quality(options) {
    if (options.q) {
      return this._operate('rquality', options.q);
    }
    return this._operate('quality', options.Q);
  }

  watermark(options) {
    if (!CosImage.ossUrl) {
      throw new Error('Please call mai.OssImage.init() first');
    }

    // 水印图片需使用 cos http 域名
    let imageUrl = options.image;
    if (imageUrl) {
      imageUrl = imageUrl.replace(/^https?:\/\/[^/]+/, '').replace(/^\//, '');
      imageUrl = `${CosImage.ossUrl}/${imageUrl}`;
    }
    const newOptions = {
      image: imageUrl,
      gravity: CosImage.transferGravity(options.g),
      text: options.text,
      font: CosImage.transferFont(options.type),
      fontsize: options.size,
      fill: `#${options.color}`,
      dissolve: options.t,
      dx: options.x,
      dy: options.y,
      degree: options.rotate,
      batch: options.fill,
    };

    for (const field of ['image', 'text', 'font', 'fill']) {
      if (newOptions[field]) {
        newOptions[field] = uniApi.encodeBase64(newOptions[field]);
      }
    }

    return this._operate('watermark', newOptions);
  }

  _operate(type, options) {
    let prefix = type === 'watermark' ? 'watermark' : 'imageMogr2';
    if (prefix === 'watermark') {
      prefix = `${prefix}/${options.image ? 1 : 2}`;
    }
    const hasImageMogr2 = this.url.includes('imageMogr2/');
    const hasWatermark = this.url.includes('watermark/');
    if (!hasImageMogr2 && !hasWatermark) {
      this.url += this.url.includes('?') ? '&' : '?';
    } else {
      this.url += '|';
    }
    if (type === 'watermark') {
      this.url += `${prefix}/`;
    } else {
      this.url += `${prefix}/${type}/`;
    }

    if (typeof options !== 'object') {
      this.url += `${options}`;
    } else {
      this.url += Object.keys(options)
        .filter((key) => options[key] !== undefined)
        .map((key) => `${key}/${options[key]}`)
        .join('/');
    }

    return this;
  }
}

export default CosImage;
