const debounce = (func, wait, immediate) => {
  let timer = null;
  let result = null;

  function debounced(...args) {
    const context = this;
    if (timer) {
      clearTimeout(timer);
    }
    if (immediate) {
      const callNow = !timer;
      timer = setTimeout(() => { timer = null; }, wait);
      if (callNow) result = func.apply(context, args);
    } else {
      timer = setTimeout(() => func.apply(context, args), wait);
    }
    return result;
  }

  debounced.cancel = () => {
    clearTimeout(timer);
    timer = null;
  };

  return debounced;
};

export default debounce;
