import qs from 'qs';
import { isH5 } from './platform';
import { toCamelCase } from './string';
import uniApi from './uniApi';

const utmProperties = {};
let launchOptions = null;

const getUtmFields = (properties) => {
  const propertiesCopy = { ...properties };
  Object.keys(propertiesCopy).forEach((key) => {
    // 如果同时存在驼峰和下划线的参数，优先使用驼峰参数
    if (key.includes('_') && !propertiesCopy[toCamelCase(key)]) {
      propertiesCopy[toCamelCase(key)] = propertiesCopy[key];
    }
  });
  const utmFields = ['utmSource', 'utmContent', 'utmMedium', 'utmCampaign', 'utmTerm'];
  const utmProperties = {};
  utmFields.forEach((field) => {
    if (propertiesCopy[field]) {
      utmProperties[field] = decodeURIComponent(propertiesCopy[field]);
    }
  });
  if (propertiesCopy.gdt_vid) {
    utmProperties.utmContent = propertiesCopy.gdt_vid;
  }
  if (propertiesCopy.weixinadinfo) {
    utmProperties.utmTerm = propertiesCopy.weixinadinfo;
  }
  if (propertiesCopy.gdt_vid && propertiesCopy.utmCampaign) {
    utmProperties.utmSource = 'wechat_ad';
  }
  return utmProperties;
};

const getLaunchOptions = () => {
  if (!isH5) {
    return uniApi.getLaunchOptionsSync();
  }

  if (!launchOptions) {
    const [hashPath] = window.location.hash.split('?') || [];
    launchOptions = {
      path: window.location.pathname + hashPath,
      query: {
        ...qs.parse(window.location.search, { ignoreQueryPrefix: true }),
        ...qs.parse(window.location.hash.replace(/^#[^?]*\?/, '')),
      },
    };
  }

  return launchOptions;
};

const getUtmFromUrl = () => {
  const { query } = getLaunchOptions();
  return getUtmFields(query);
};

const getUtmFromScene = () => {
  const { scene } = getLaunchOptions();
  if (scene) {
    return { utmSource: `mp_${scene}` };
  }

  return {};
};

Object.assign(utmProperties, getUtmFromScene(), getUtmFromUrl());

const getUtmProperties = () => utmProperties;

const setUtmProperties = (properties) => {
  Object.assign(utmProperties, getUtmFields(properties));
};

export {
  getUtmProperties,
  setUtmProperties,
  getLaunchOptions,
};
