import { isWechatApp, isWechatWorkApp, isAlipayApp, isBytedanceApp, isH5 } from './platform';
import { isFunction } from './lang';
import promisify from './promisify';

// 微信小程序原生 API 集合 https://developers.weixin.qq.com/miniprogram/dev/api/
const weappApis = [
  'uploadFile',
  'downloadFile',
];

// 企业微信小程序 https://developer.work.weixin.qq.com/document/path/91506
const qyWeappApis = [
  'login',
  'checkSession',
  'canIUse',
  'getEnterpriseUserInfo',
  'getAvatar',
  'getQrCode',
  'selectEnterpriseContact',
  'selectExternalContact',
  'openUserProfile',
  'openEnterpriseChat',
  'getCurExternalContact',
  'shareToExternalContact',
  'getCurExternalChat',
  'sendChatMessage',
  'shareToExternalChat',
];

// 支付宝小程序 https://opendocs.alipay.com/mini/api
const alipayApis = [
  'getAuthCode',
];

// 字节小程序 https://microapp.bytedance.com/docs/zh-CN/mini-app/develop/api/foundation/tt-can-i-use
const bytedanceApis = [
];

/**
 * （企业）微信、支付宝、字节小程序 JSSDK 封装类，使用 API 支持 Promise。
 * @private
 */
class UniApi {
  instance = {};

  qy = {};

  /**
   * 创建 UniApi 实例
   */
  constructor() {
    if (isWechatWorkApp) {
      qyWeappApis.forEach((api) => {
        this.qy[api] = (...args) => promisify(wx.qy[api], ...args);
      });
    }

    if (isWechatApp) {
      this.initApis(wx, weappApis);
    }

    if (isAlipayApp) {
      this.initApis(my, alipayApis);
    }

    if (isBytedanceApp) {
      this.initApis(tt, bytedanceApis);
    }
  }

  initApis = (instance, apis) => {
    this.instance = instance;
    Object.keys(instance).forEach((api) => {
      if (!isFunction(instance[api]) || this[api]) {
        return;
      }

      if (apis.includes(api)) {
        this[api] = (...args) => promisify(instance[api], ...args);
      } else {
        this[api] = instance[api];
      }
    });
  }

  getStorageSync = (key) => {
    const result = this.instance.getStorageSync(key);
    if (isAlipayApp) {
      // https://opendocs.alipay.com/mini/api/ox0wna
      return result.data;
    }

    return result;
  }

  encodeBase64 = (text = '') => {
    const bytes = [];
    for (let index = 0; index < text.length; index++) {
      const c = text.charCodeAt(index);
      if (c >= 0x010000 && c <= 0x10FFFF) {
        bytes.push(((c >> 18) & 0x07) | 0xF0);
        bytes.push(((c >> 12) & 0x3F) | 0x80);
        bytes.push(((c >> 6) & 0x3F) | 0x80);
        bytes.push((c & 0x3F) | 0x80);
      } else if (c >= 0x000800 && c <= 0x00FFFF) {
        bytes.push(((c >> 12) & 0x0F) | 0xE0);
        bytes.push(((c >> 6) & 0x3F) | 0x80);
        bytes.push((c & 0x3F) | 0x80);
      } else if (c >= 0x000080 && c <= 0x0007FF) {
        bytes.push(((c >> 6) & 0x1F) | 0xC0);
        bytes.push((c & 0x3F) | 0x80);
      } else {
        bytes.push(c & 0xFF);
      }
    }

    let base64Str = '';
    if (isH5) {
      let binary = '';
      for (let index = 0; index < bytes.length; index++) {
        binary += String.fromCharCode(bytes[index]);
      }
      base64Str = btoa(binary);
    } else {
      base64Str = this.instance.arrayBufferToBase64(new Uint8Array(bytes));
    }

    return base64Str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
}

export default new UniApi();
