import qs from 'qs';
import UAParser from 'ua-parser';
import { request, getClientId } from '../utils/request';
import { isObject } from '../utils/lang';
import { getLocalISOString } from '../utils/date';
import { uuid } from '../utils/string';

import { getLaunchOptions, getUtmProperties } from '../utils/utm';
import DebounceBuffer from '../utils/DebounceBuffer';
import Member from '../Member';
import { isH5, isWechatApp } from '../utils/platform';
import uniApi from '../utils/uniApi';

const urlContentMap = {};

const urlPropertiesWillBeIgnored = [
  'redirectUrl',
  'RedirectUrl',
  'redirect-url',
];

const platform = {
  sdk: 'js', // SDK 类型
  sdkVersion: process.env.SDK_VERSION, // maijs 版本
};

if (isH5) {
  const { userAgent } = window.navigator;
  const { device, os, browser } = UAParser(userAgent);
  Object.assign(platform, {
    userAgent, // 用户代理字符串
    screenWidth: window.screen.width, // 屏幕宽度
    screenHeight: window.screen.height, // 屏幕高度
    manufacturer: device.vendor, // 设备制造商
    model: device.model, // 设备型号
    os: os.name, // 操作系统
    osVersion: os.version, // 操作系统版本
    browser: browser.name, // 浏览器
    browserVersion: browser.version, // 浏览器版本
  });
} else {
  const res = uniApi.getSystemInfoSync();
  const [os, osVersion] = res.system.split(' ');
  Object.assign(platform, {
    screenWidth: res.screenWidth,
    screenHeight: res.screenHeight,
    manufacturer: res.brand,
    model: res.model,
    os,
    osVersion,
  });
}

/**
 * 基础事件
 */
class Event {
  /**
   * @private
   * @type {DebounceBuffer}
   */
  static _buffer = null;

  /**
   * 创建 Event 实例
   */
  constructor() {
    const { _buffer } = Event;
    if (_buffer) {
      this._buffer = _buffer;
    } else {
      this._buffer = new DebounceBuffer(this.report.bind(this));
      Event._buffer = this._buffer;
    }
  }

  /**
   * 上报客户事件
   *
   * @private
   * @param {object[]} logs - 客户事件缓存数组
   * @returns {Promise<object>} 发送请求后的回调
   */
  report(logs) {
    const data = { clientId: getClientId(), logs };
    if (Member.instance.getChannelId()) {
      data.channelId = Member.instance.getChannelId();
    }

    return request.post('/v2/memberEventLogs', data);
  }

  /**
   * 记录客户事件
  * @private
   *
   * @param {string} name - 事件名称，需与群脉环境和账号下面的事件名匹配
   * @param {object} properties - 事件属性，需与事件名称下的属性匹配，可以在客户行为记录中展示出来
   * @returns {object} 需要记录的客户事件
   */
  log(name, properties) {
    Event.validate(name, properties);
    const { url, content } = Event.getPlatform();
    const { scene } = getLaunchOptions();
    const mergedProperties = { url, scene, pageContent: content, ...getUtmProperties(), ...properties };
    if (isObject(properties.extra)) {
      properties.extra = JSON.stringify(properties.extra);
    }
    const data = Event.getLog(name, mergedProperties);
    this._buffer.debounce(data);
    return data;
  }

  /**
   * 记录页面停留时间
   * @private
   *
   * @param {object} preLog - 需要记录页面停留时间的客户事件
   */
  logDuration(preLog) {
    const logCopy = { ...preLog };
    const durationInSeconds = parseInt((new Date().valueOf() - new Date(preLog.occurredAt).valueOf()) / 1000, 10);
    logCopy.properties = JSON.stringify({ durationInSeconds });
    this._buffer.debounce(logCopy);
  }

  /**
   * 获取客户事件对象
   *
   * @private
   * @param {string} name - 事件名称
   * @param {object} properties - 事件属性
   * @returns {object} 客户事件对象
   */
  static getLog(name, properties) {
    return {
      name,
      properties: JSON.stringify(properties),
      id: uuid(),
      occurredAt: getLocalISOString(),
    };
  }

  /**
   * 验证参数类型
   *
   * @private
   * @param {string} name - 事件名称
   * @param {object} properties - 事件属性
   */
  static validate(name, properties) {
    if (!name) {
      throw new Error('name is required');
    }
    if (properties && !isObject(properties)) {
      throw new Error('The properties parameter should be a key-value object');
    }
  }

  /**
   * 验证必填字段
   *
   * @private
   * @param {string} obj - 需要校验的对象
   * @param {object} fields - 需要校验的字段
   */
  static validateRequiredFields(obj, fields) {
    if (!isObject(obj) || !Array.isArray(fields)) {
      return;
    }
    fields.forEach((field) => {
      if (!obj[field]) {
        throw new Error(`${field} is required`);
      }
    });
  }

  static getPlatform = () => {
    if (isWechatApp) {
      const pages = getCurrentPages();
      let url = '';
      if (pages.length) {
        const currentPage = pages[pages.length - 1];
        url = Event.getUrl(currentPage.route, currentPage.options);
      } else {
        const launchOptions = wx.getLaunchOptionsSync();
        url = Event.getUrl(launchOptions.path, launchOptions.query);
      }
      platform.url = url;
      platform.content = Event.getPageContent(url);
    } else if (isH5) {
      platform.url = window.location.href;
      platform.content = document.title;
    }

    return platform;
  };

  static getPath = (url) => {
    const idx = url.indexOf('?');
    return idx === -1 ? url : url.slice(0, idx);
  };

  static setPageContent = (url, content) => {
    urlContentMap[Event.getPath(url)] = content;
  };

  static getPageContent = (url) => {
    return urlContentMap[Event.getPath(url)];
  };

  static getUrl = (path, options) => {
    const newOptions = {};
    for (const key in options) {
      if (urlPropertiesWillBeIgnored.includes(key)) {
        continue;
      }
      if (typeof options[key] === 'object') {
        continue;
      }
      newOptions[key] = options[key];
    }
    const queryStr = qs.stringify(newOptions);
    const connector = queryStr ? '?' : '';
    return `${path}${connector}${queryStr}`;
  };
}

export default Event;
