import Event from './Event';

/**
 * 交易运营事件
 */
class Trade extends Event {
  /**
   * @private
   * @type {Trade}
   */
  static _instance = null;

  /**
   * 创建交易运营事件实例(单例)
   *
   * @extends Event
   */
  constructor() {
    super();
    const { _instance } = Trade;
    if (_instance) {
      return _instance;
    }
    Trade._instance = this;
    return this;
  }

  /**
   * 记录浏览商品详情事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.storeId] - 店铺ID
   * @param {string} [properties.storeCode] - 店铺编号
   * @param {string} [properties.storeName] - 店铺名称
   * @param {string} properties.content - 页面名称
   * @param {string} [properties.url] - 页面路径
   * @param {string} properties.productId - 商品 ID
   * @param {string} properties.productName - 商品名称
   * @param {string} [properties.refererUrl] - 上一页面路径
   * @param {string} properties.primaryImgUrl  - 商品主图片地址
   * @returns {object} 上报的 log 对象
   */
  logProductView(properties = {}) {
    Event.validateRequiredFields(properties, ['content', 'productId', 'productName', 'primaryImgUrl']);
    return this.log('maievent-product-view', properties);
  }

  /**
   * 记录商品浏览页面停留时间（离开商品详情页调用，需和 logProductView 配对使用）
   *
   * @param {string} log - logProductView() 返回的 log 对象
   */
  logProductViewDuration(log) {
    if (!log) {
      throw new Error('log is required');
    }
    this.logDuration(log);
  }

  /**
   * 记录进入SKU选择页事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.storeId] - 店铺ID
   * @param {string} [properties.storeCode] - 店铺编号
   * @param {string} [properties.storeName] - 店铺名称
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.area] - 流量位区域
   * @param {string} [properties.content] - 操作内容
   * @param {string} [properties.productId] - 商品ID
   * @param {string} [properties.productNumber] - 商品编号
   * @param {string} [properties.productName] - 商品名称
   */
  logProductSkuSelect(properties = {}) {
    this.log('maievent-product-sku-select', properties);
  }

  /**
   * 记录单商品加入购物车事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.storeId] - 店铺ID
   * @param {string} [properties.storeCode] - 店铺编号
   * @param {string} [properties.storeName] - 店铺名称
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.area] - 流量位区域
   * @param {string} properties.content - 操作内容
   * @param {string} properties.productId - 商品ID
   * @param {string} [properties.productNumber] - 商品编号
   * @param {string} properties.productName - 商品名称
   * @param {string} [properties.productSku] - 商品SKU编号
   * @param {number} [properties.count] - 加购数量
   * @param {number} [properties.originPrice] - 商品原价，单位：元
   * @param {number} [properties.price] - 优惠后商品单价，货币单位为元，如 10.0
   */
  logProductAddToCart(properties = {}) {
    Event.validateRequiredFields(properties, ['content', 'productId', 'productName']);
    this.log('maievent-product-add-to-cart', properties);
  }

  /**
   * 记录多商品加入购物车事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.storeId] - 店铺ID
   * @param {string} [properties.storeCode] - 店铺编号
   * @param {string} [properties.storeName] - 店铺名称
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.area] - 流量位区域
   * @param {string} properties.content - 操作内容
   * @param {object[]} properties.goodsList - 商品列表
   * @param {string} [properties.goodsList.productId] - 商品ID
   * @param {string} [properties.goodsList.productNumber] - 商品编号
   * @param {string} [properties.goodsList.productName] - 商品名称
   * @param {string} [properties.goodsList.productSku] - 商品SKU编号
   * @param {number} [properties.goodsList.count] - 加购数量
   * @param {number} [properties.goodsList.originPrice] - 商品原价，单位：元
   * @param {number} [properties.goodsList.price] - 优惠后商品单价，货币单位为元
   */
  logBatchProductAddToCart(properties = {}) {
    Event.validateRequiredFields(properties, ['content', 'goodsList']);
    this.log('maievent-batch-product-add-to-cart', properties);
  }

  /**
   * 记录立即购买事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.storeId] - 店铺ID
   * @param {string} [properties.storeCode] - 店铺编号
   * @param {string} [properties.storeName] - 店铺名称
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.area] - 流量位区域
   * @param {string} [properties.content] - 操作内容
   * @param {object[]} properties.goodsList - 商品列表
   * @param {string} [properties.goodsList.productId] - 商品ID
   * @param {string} [properties.goodsList.productNumber] - 商品编号
   * @param {string} [properties.goodsList.productName] - 商品名称
   * @param {string} [properties.goodsList.productSku] - 商品SKU编号
   * @param {number} [properties.goodsList.count] - 加购数量
   * @param {number} [properties.goodsList.originPrice] - 商品原价，单位：元
   * @param {number} [properties.goodsList.price] - 优惠后商品单价，货币单位为元
   */
  logWantToBuy(properties = {}) {
    Event.validateRequiredFields(properties, ['goodsList']);
    this.log('maievent-product-buy', properties);
  }

  /**
   * 记录立即下单事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.storeId] - 店铺ID
   * @param {string} [properties.storeCode] - 店铺编号
   * @param {string} [properties.storeName] - 店铺名称
   * @param {string} [properties.orderId] - 订单ID
   * @param {string} [properties.orderNumber] - 订单编号
   * @param {string} [properties.platform] - 下单平台
   * @param {string} [properties.orderAmount] - 下单金额
   * @param {string} [properties.goodsAmount] - 商品总价
   * @param {string} [properties.discountAmount] - 优惠金额
   * @param {string} [properties.postalAmount] - 邮费
   * @param {object[]} [properties.goodsList] - 商品列表
   * @param {string} [properties.goodsList.productId] - 商品ID
   * @param {string} [properties.goodsList.productNumber] - 商品编号
   * @param {string} [properties.goodsList.productName] - 商品名称
   * @param {string} [properties.goodsList.productSku] - 商品SKU编号
   * @param {number} [properties.goodsList.count] - 加购数量
   * @param {number} [properties.goodsList.originPrice] - 商品原价，单位：元
   * @param {number} [properties.goodsList.price] - 优惠后商品单价，货币单位为元
   * @param {object[]} [properties.couponList] - 优惠券列表
   * @param {string} [properties.couponList.couponId] - 优惠券编号
   * @param {string} [properties.couponList.couponName] - 优惠券名称
   */
  logOrderCreated(properties = {}) {
    this.log('maievent-order-created', properties);
  }

  /**
   * 记录点击结算事件
   *
   * @deprecated since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.storeCode] - 店铺编号
   * @param {string} [properties.storeName] - 店铺名称
   * @param {string} properties.content - 操作内容
   * @param {Object[]} properties.productList - 商品列表
   * @param {string} properties.productList[].productId - 商品 ID
   * @param {string} properties.productList[].productName - 商品名称
   * @param {object} [properties.productList[].goodsInfo] - 可选，商品信息
   * @param {string} [properties.productList[].goodsInfo.productSku] - sku 编号
   * @param {string} [properties.productList[].goodsInfo.specs] - 规格，如 30cm*150m
   * @param {string} [properties.productList[].goodsInfo.brand] - 品牌，如妙洁
   * @param {number} [properties.productList[].goodsInfo.count] - 购买数量，如 10
   * @param {number} [properties.productList[].goodsInfo.price] - 优惠后商品单价，货币单位为元，如 10.0
   */
  // eslint-disable-next-line no-unused-vars
  logCheckout(properties = {}) {
    // eslint-disable-next-line no-console
    console.warn('The current api "Trade.logCheckout" has been deprecated and disabled, please remove it ASAP');
  }
}

export default Trade;
