import Event from './Event';
import { isH5 } from '../utils/platform';
import uniApi from '../utils/uniApi';

/**
 * 流量运营事件
 *
 * @extends Event
 */
class Traffic extends Event {
  /**
   * @private
   * @type {Traffic}
   */
  static _instance = null;

  /**
   * 创建流量运营事件实例(单例)
   */
  constructor() {
    super();
    const { _instance } = Traffic;
    if (_instance) {
      return _instance;
    }
    Traffic._instance = this;
    return this;
  }

  /**
   * 记录应用启动事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.scene]  - 可选，小程序场景值 ID，比如 1001。如果不传，SDK 自动添加
   * @param {string} [properties.appId]  - 可选，小程序 appId。如果不传，SDK 自动添加
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   * @param {string} [properties.queryScene] - 可选，小程序码 scene
   */
  logAppLaunch(properties = {}) {
    this.log('maievent-app-launch', this._mergeAppInfo(properties));
  }

  /**
   * 记录小程序显示事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.scene]  - 可选，小程序场景值 ID。如果不传，SDK 自动添加
   * @param {string} [properties.appId]  - 可选，小程序 appId。如果不传，SDK 自动添加
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   * @param {string} [properties.queryScene]  - 可选，小程序码 scene
   */
  logAppShow(properties = {}) {
    this.log('maievent-app-show', this._mergeAppInfo(properties));
  }

  /**
   * 记录小程序隐藏事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   */
  logAppExit(properties = {}) {
    this.log('maievent-app-exit', properties);
  }

  /**
   * 记录分享页面事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.url] - 页面路径
   * @param {string} [properties.fromType] - 转发事件来源
   * @param {string} [properties.shareTo] - 分享类型
   * @param {string} [properties.sharePath] - 转发页面路径，不填默认是当前页面。通过路径解析分享的entity
   * @param {string} [properties.shareTitle] - 转发标题
   * @param {string} [properties.shareImageUrl] - 转发图片路径
   */
  logPageShare(properties = {}) {
    this.log('maievent-page-share', properties);
  }

  /**
   * 记录页面离开事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   * @param {string} [properties.durationInSeconds] - 停留时长(s)
   */
  logPageLeave(properties = {}) {
    this.log('maievent-page-leave', properties);
  }

  _mergeAppInfo(properties = {}) {
    if (!isH5) {
      if (!properties.appId) {
        const { miniProgram } = uniApi.getAccountInfoSync();
        properties.appId = miniProgram.appId;
      }
      if (!properties.scene) {
        const { scene } = uniApi.getLaunchOptionsSync();
        properties.scene = scene || 1000; // 部分机型获取不到 scene，默认赋值其他场景
      }

      if (!properties.scene || !properties.appId) {
        throw new Error('scene and appId are required');
      }
    }
    return properties;
  }
}

export default Traffic;
