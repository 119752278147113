export function createCacheStore() {
  const store = Object.create(null);

  /**
   * @param {string} cacheKey
   * @param {function} compute 计算函数，缓存不存在或需要刷新缓存时调用该函数
   * @param {boolean}[refresh=false] 是否刷新缓存
   * @return {Promise<any>} 获取到的值
   */
  return async function getCache(cacheKey, compute, refresh = false) {
    if (refresh) {
      store[cacheKey] = await compute();
      return store[cacheKey];
    }

    let cached = store[cacheKey];
    if (!cached) {
      cached = await compute();
      store[cacheKey] = cached;
    }
    return cached;
  };
}
