import { isObject } from '../utils/lang';
import { isH5 } from '../utils/platform';
import sessionStorage from '../utils/sessionStorage';

import { getUtmProperties } from '../utils/utm';
import Event from './Event';

/**
 * 页面事件
 */
class Page extends Event {
  /**
   * @private
   * @type {Page}
   */
  static _instance = null;

  /**
   * 创建页面事件实例(单例)
   *
   * @extends Event
   */
  constructor() {
    super();

    this._pvKey = 'mai_page_view_key';

    const { _instance } = Page;
    if (_instance) {
      return _instance;
    }

    if (isH5) {
      window.addEventListener('beforeunload', () => {
        const buffer = sessionStorage.getItem(this._pvKey) || [];
        if (buffer.length) {
          const preLog = buffer[0];
          this.logDuration(preLog);
        }
      });
    }

    Page._instance = this;
    return this;
  }

  /**
   * 记录 Page View 事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.utmCampaign] - 可选，访问来源所关联的活动
   * @param {string} [properties.utmContent] - 可选，访问来源内容
   * @param {string} [properties.utmTerm] - 可选，访问来源搜索关键词
   * @param {string} [properties.utmMedium] - 可选，访问来源链接类型
   * @param {string} [properties.utmSource] - 可选，访问来源
   * @param {string} [properties.content] - 可选，当前页面内容，由 SDK 自动添加（取页面标题）
   * @param {string} [properties.url] - 可选，当前页面地址，由 SDK 自动添加
   * @param {string} [properties.durationInSeconds] - 可选，停留时长(s)
   * @param {string} [properties.refererContent] - 可选，前一页内容，由 SDK 自动添加（上一次记录的）
   * @param {string} [properties.refererUrl] - 可选，要跳转到的页面内容
   * @param {string} [properties.redirectContent] - 可选，要跳转到的页面地址
   * @param {string} [properties.redirectUrl] - 可选，要跳转到的页面地址
   * @param {object} [properties.extra] - 可选，额外信息
   */
  logView(properties = {}) {
    const mergedProperties = { ...Event.getPlatform(), ...getUtmProperties(), ...properties };
    if (mergedProperties.url && mergedProperties.content) {
      Event.setPageContent(mergedProperties.url, mergedProperties.content);
    }
    if (isObject(properties.extra)) {
      properties.extra = JSON.stringify(properties.extra);
    }

    const buffer = sessionStorage.getItem(this._pvKey) || [];
    const data = Event.getLog('maievent-page-view', mergedProperties);
    const preLog = buffer.shift();
    buffer.push(data);

    if (preLog) {
      this.logDuration(preLog);

      const curProperties = JSON.parse(data.properties);
      const preProperties = JSON.parse(preLog.properties);
      curProperties.refererContent = preProperties.content;
      curProperties.refererUrl = preProperties.url;
      data.properties = JSON.stringify(curProperties);
    }
    sessionStorage.setItem(this._pvKey, buffer);
    this._buffer.debounce(data);
  }

  /**
   * 记录 Page Operate 事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.utmSource] - 可选，访问来源
   * @param {string} [properties.utmContent] - 可选，访问来源内容
   * @param {string} [properties.utmMedium] - 可选，访问来源链接类型
   * @param {string} [properties.utmCampaign] - 可选，访问来源所关联的活动
   * @param {string} [properties.utmTerm] - 可选，访问来源搜索关键词
   * @param {string} [properties.pageContent] - 可选，当前页面内容，由 SDK 自动添加（取页面标题）
   * @param {string} [properties.url] - 可选，当前页面地址，由 SDK 自动添加
   * @param {string} [properties.durationInSeconds] - 可选，停留时长(s)
   * @param {string} [properties.type] - 操作的类型，可选值：click、tap、swipe、scroll、choose、input 或自定义
   * @param {string} [properties.area] - 流量位区域
   * @param {string} [properties.content] - 操作内容
   * @param {string} [properties.targetId] - 可选，元素唯一编号
   * @param {string} [properties.redirectContent] - 可选，操作后要跳转到的页面内容
   * @param {string} [properties.redirectUrl] - 可选，操作后要跳转到的页面地址
   * @param {object} [properties.extra] - 可选，额外信息
   */
  logOperate(properties = {}) {
    this.log('maievent-page-operate', properties);
  }
}

export default Page;
