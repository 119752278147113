/* eslint-disable import/no-mutable-exports */
/* global uni */

// taro 会注入 window 和 document 对象
// uni-app 会为不同平台尽可能提供相同 wx 接口，[https://uniapp.dcloud.io/api/other/open-miniprogram?id=navigatetominiprogram](不支持 H5)

let isWechatApp = false;
let isAlipayApp = false;
let isBytedanceApp = false;
let isH5 = false;

if (typeof uni === 'object') {
  const { uniPlatform } = uni.getSystemInfoSync();
  isWechatApp = uniPlatform === 'mp-weixin';
  isAlipayApp = uniPlatform === 'mp-alipay';
  isBytedanceApp = uniPlatform === 'mp-toutiao';
  isH5 = uniPlatform === 'web';
} else {
  isWechatApp = typeof wx === 'object' && wx.getSystemInfoSync;
  isAlipayApp = typeof my === 'object';
  isBytedanceApp = typeof tt === 'object';
  isH5 = !isWechatApp && !isAlipayApp && !isBytedanceApp && typeof window === 'object' && typeof navigator === 'object';
}

const isWechatWorkApp = isWechatApp && wx.getSystemInfoSync().environment === 'wxwork';
const isWechatWorkH5 = isH5 && /wxwork/i.test(navigator.userAgent);

export {
  isWechatApp,
  isAlipayApp,
  isBytedanceApp,
  isH5,
  isWechatWorkH5,
  isWechatWorkApp,
};
