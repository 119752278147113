import Event from './Event';
/**
 * 积分运营事件
 */
class Score extends Event {
  /**
   * @private
   * @type {Score}
   */
  static _instance = null;

  /**
   * 创建积分运营事件实例(单例)
   *
   * @extends Event
   */
  constructor() {
    super();
    const { _instance } = Score;
    if (_instance) {
      return _instance;
    }
    Score._instance = this;
    return this;
  }

  /**
   * 记录浏览积分商品详情事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径
   * @param {string} [properties.refererUrl] - 上一页面路径
   * @param {string} [properties.durationInSeconds] - 停留时长(s)
   * @param {string} [properties.productId] - 商品id
   * @param {string} [properties.productNumber] - 商品编号
   * @param {string} [properties.productName] - 商品名称
   * @param {number} [properties.originScore] - 原始积分数
   * @param {number} [properties.score] - 所需积分数
   */
  logScoreProductView(properties = {}) {
    return this.log('maievent-score-product-view', properties);
  }

  /**
   * 记录提交兑换申请事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.orderId] - 订单编号
   * @param {number} [properties.orderAmount] - 实际下单金额，含运费，单位为元
   * @param {number} [properties.totalScore] - 兑换总积分
   * @param {number} [properties.score] - 实际所需积分
   * @param {object[]} [properties.goodsList] - 商品列表
   * @param {string} [properties.goodsList[].productId] - 商品 ID
   * @param {string} [properties.goodsList[].productNumber] - 商品编号
   * @param {string} [properties.goodsList[].productName] - 商品名称
   * @param {number} [properties.goodsList[].originScore] - 原始积分数
   * @param {number} [properties.goodsList[].score] - 所需积分数
   * @param {number} [properties.goodsList[].count] - 兑换数量
   * @param {string} [properties.remark] - 备注
   * @param {string} [properties.deliveryType] - 取货方式
   */
  logScoreExchangeSubmit(properties = {}) {
    this.log('maievent-score-exchange-submit', properties);
  }

  /**
   * 记录取消积分商品兑换事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.orderId] - 订单 ID
   * @param {string} [properties.orderNumber] - 订单编号
   */
  logScoreExchangeCancel(properties = {}) {
    this.log('maievent-score-exchange-cancel', properties);
  }
}

export default Score;
