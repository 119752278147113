import Event from './Event';
import Enums from '../Enums';
/**
 * 功能运营事件
 */
class Feature extends Event {
  /**
   * @private
   * @type {Feature}
   */
  static _instance = null;

  /**
   * 创建功能运营事件实例(单例)
   *
   * @extends Event
   */
  constructor() {
    super();
    const { _instance } = Feature;
    if (_instance) {
      return _instance;
    }
    Feature._instance = this;
    return this;
  }

  /**
   * 记录切换底部模块 Tab 事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} properties.content - 操作内容
   * @param {string} [properties.area] - 流量位区域，如 tab
   */
  logTabClick(properties = {}) {
    Event.validateRequiredFields(properties, ['content']);
    this.log('maievent-page-operate', properties);
  }

  /**
   * 记录联系客服事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.area] - 流量位区域
   */
  logContact(properties = {}) {
    properties.content = '联系客服';
    this.log('maievent-page-operate', properties);
  }

  /**
   * 记录点击个人信息事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.area] - 流量位区域
   */
  logProfileClick(properties = {}) {
    properties.content = '个人信息';
    this.log('maievent-page-operate', properties);
  }

  /**
   * 记录点击积分事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.area] - 流量位区域
   */
  logScoreClick(properties = {}) {
    properties.content = '积分';
    this.log('maievent-page-operate', properties);
  }

  /**
   * 记录点击优惠券事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} properties.statusTab - 优惠券状态：全部、未使用、已使用、已过期
   * @param {string} [properties.area] - 流量位区域
   */
  logCouponTabClick(properties = {}) {
    Event.validateRequiredFields(properties, ['statusTab']);
    properties.content = '优惠券';
    this.log('maievent-page-operate', properties);
  }

  /**
   * 记录点击我的订单事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} properties.statusTab - 订单状态
   * @param {string} [properties.area] - 流量位区域
   */
  logOrderTabClick(properties = {}) {
    Event.validateRequiredFields(properties, ['statusTab']);
    properties.content = '我的订单';
    this.log('maievent-page-operate', properties);
  }

  /**
   * 记录搜索关键词事件
   * 触发点有两个：1. 用户点击进入搜索框后 2. 输入关键词点击搜索后。二者都会触发此事件。只不过前者关键词为空，后者关键词为搜索内容。
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} properties.keyword - 搜索关键字
   * @param {Enums.SearchActions} [properties.searchType] - 搜索类型
   */
  logSearch(properties = {}) {
    Event.validateRequiredFields(properties, ['keyword']);
    if (properties.searchType && !Object.values(Enums.SearchActions).includes(properties.searchType)) {
      throw new Error(`The searchType parameter should be in ${Object.values(Enums.SearchActions)}`);
    }
    this.log('maievent-page-search', properties);
  }

  /**
   * 记录完善个人资料事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   */
  logProfileSubmit(properties = {}) {
    this.log('maievent-profile-submit', properties);
  }
}

export default Feature;
