import { isH5 } from './platform';

const sessionStorage = {
  setItem: () => { },
  getItem: () => { },
  removeItem: () => { },
};

const WEAPP_STORAGE_KEY = 'mai_session_storage';

if (isH5) {
  sessionStorage.setItem = (key, value) => {
    const jsonStr = JSON.stringify(value);
    window.sessionStorage.setItem(key, jsonStr);
  };

  sessionStorage.getItem = (key) => {
    const jsonStr = window.sessionStorage.getItem(key);
    return JSON.parse(jsonStr);
  };

  sessionStorage.removeItem = (key) => {
    window.sessionStorage.removeItem(key);
  };
} else {
  // 1. 插件中没有 getApp 方法；2. 在执行 App.onLaunch 前执行 getApp 返回 undefined
  const app = (getApp && getApp()) || {};
  if (!app[WEAPP_STORAGE_KEY]) {
    app[WEAPP_STORAGE_KEY] = {};
  }

  sessionStorage.setItem = (key, value) => {
    app[WEAPP_STORAGE_KEY][key] = value;
  };

  sessionStorage.getItem = (key) => app[WEAPP_STORAGE_KEY][key];

  sessionStorage.removeItem = (key) => {
    delete app[WEAPP_STORAGE_KEY][key];
  };
}

export default sessionStorage;
